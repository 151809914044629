import React from "react"
import Helmet from "react-helmet"
import icon from "../../img/favicon.png"

export default ({ title = "", descr = "", slug = "", admin = false }) => {
  return (
    <Helmet>
      <title>
        {title
          ? `${title} · PBCBA CLE Ordering`
          : `PBCBA CLE Ordering · Courses`}
      </title>
      <meta name="description" content={descr} />
      <meta property="og:url" content={`https://cle.palmbeachbar.org${slug}`} />
      <meta
        property="og:title"
        content={title ? `${title} · PBCBA CLE Ordering` : `PBCBA CLE Ordering`}
      />
      )}
      <meta property="og:description" content={descr} />
      <meta
        property="og:image"
        content="https://cle.palmbeachbar.org/og-logo.png"
      />
      <meta property="og:image:alt" content="PBCBA Logo" />
      <meta property="og:type" content="website" />
      <link rel="shortcut icon" href={icon} />
      {admin && <meta name="robots" content="noindex" />}
    </Helmet>
  )
}
